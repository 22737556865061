'use client'

import { useEffect } from 'react'
import { useRouter } from 'next/navigation'

export default function NotFound () {
  const router = useRouter()

  useEffect(() => {
    // Detecta el idioma del navegador
    const language = navigator.language

    // Redirige según el idioma
    if (language.startsWith('es')) {
      router.replace('/es/not-found')
    } else {
      router.replace('/en/not-found')
    }
  }, [router])

  return null
}
